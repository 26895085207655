import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'dashboard',
		meta: {
			breadcrumb: [{parent:'Accueil', label:''}]
		},
		exact: true,
		component: () => import('./components/Dashboard.vue')
	},
	{
		path: '/parametre',
		name: 'parametre',
		meta: {
			breadcrumb: [{parent:'UI Kit', label:'Input'}]
		},
		component: () => import('./components/ParametrePage.vue')
	},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;