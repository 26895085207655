<template>
	<div class="layout-footer" >
        <div class="p-grid">
            <div class="p-col-12 p-md-6  p-lg-3">
                 <span class="logo">ADSEI </span>
                <p>Harmony 1.0.7</p>
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
