<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo" @click="$router.push({path: '/'})">
      <img class="logo" src="assets/layout/images/logo.png" style="height: 100%" alt="">
    </div>
    <!--

            <a class="layout-menu-button" href="#" @click="onMenuButtonClick">
                <i class="pi pi-bars"></i>
            </a>
    -->

    <ul class="layout-topbar-menu">

      <li :class="[{'active-topmenuitem': activeTopbarItem === 'profile'}]" class="user-profile"
          @click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
        <a href="#">
          <div class="user-profile-info profile-link" v-show="online">
            <span class="user-profile-name p-mt-1">En ligne </span>
          </div>
          <Button icon="pi pi-check" class="p-button-rounded p-button-success p-mr-2 p-mb-2" v-show="online"/>
        </a>
        <a href="#">
          <div class="user-profile-info profile-link" v-show="!online">
            <span class="user-profile-name p-mt-1">Hors ligne </span>
          </div>
          <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2 p-mb-2" v-show="!online"/>
        </a>
        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
            <li>
              <router-link to="parametre">
                <a href="#">
                  <div class="menu-text p-mt-1">
                    <span class="menu-title"><i class="pi pi-cog p-mr-3"></i> Paramètre</span>
                  </div>
                </a>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
    </ul>

  </div>
</template>

<script>
import axios from 'axios'
import https from 'https';

export default {
  data() {
    return {
      online: false,
      server: null,
      started: null
    }
  },
  emits: ['topbar-item-click', 'menubutton-click', 'topbar-menubutton-click'],
  props: {
    topbarMenuActive: Boolean,
    horizontal: Boolean,
    activeTopbarItem: String
  },
  methods: {
    ping() {
      if (this.server) {
        axios.get(this.server + '/webapi/entry.cgi?api=SYNO.FileStation.Info&version=1', {
          httpsAgent: new https.Agent({
            rejectUnauthorized: false
          })
        }).then(response => {
          if (response.status === 200) {
            this.online = true
          } else {
            this.online = false
          }

        }).catch(() => {
          this.online = false
        });
      }
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event);
    }
  },
  computed: {},
  mounted() {
    if (localStorage.server) {
      this.server = localStorage.server;
    }
    this.ping();
    this.started = setInterval(this.ping, 10000);
  },
}
</script>
